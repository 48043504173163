import { assets } from "@koble/assets/assets";
import { externalLinks } from "@koble/utils/src";

const es = {
  switch: {
    header: {
      navbar: {
        userStudent: "Talento joven",
        userStudentTitle: "Talento joven",
        userRecruiter: "Reclutadores",
        userRecruiterTitle: "Reclutadores",
        blog: "Blog",
        blogTitle: "Blog",
        signIn: "Iniciar sesión",
        signInTitle: "Iniciar sesión",
        signUp: "Crea tu cuenta gratis",
        signUpTitle: "Crea tu cuenta gratis",
        goToApp: "Ir a la app",
        goToAppTitle: "Ir a la app",
        logoAlt: "Logo de Koble, la plataforma de empleo para jóvenes talentos",
        logoTitle: "Koble",
      },
      content: {
        switch: {
          userStudent: "Talento joven",
          userRecruiter: "Reclutadores",
          title: "Cambiar a",
        },
        title: {
          userStudent:
            "¿Buscas tu primer empleo? ¡Somos la mejor página para encontrar trabajo!",
          userRecruiter: "Encuentra el talento joven que estás buscando",
        },
        subTitle: {
          userStudent:
            "Entérate de las mejores vacantes dirigidas a estudiantes de carrera profesional o recién egresados. Crea tu perfil y deja que tu CV se encuentre con los mejores empleos, ¡tenemos una bolsa de trabajo a tu medida!",
          userRecruiter:
            "Somos el sitio especializado en reclutamiento de estudiantes y recién egresados. Utilizamos inteligencia artificial para hacer un emparejamiento preciso entre lo que buscan las empresas, y lo que ofrecen los postulantes. Publica vacantes, explora perfiles de candidatos y gestiona el reclutamiento de manera eficiente.",
        },
        button: {
          userStudent: "Buscar oferta de trabajo",
          userRecruiter: "Crea una vacante",
        },
        buttonDemo: {
          userStudent: "Solicitar orientación",
          userRecruiter: "Reservar una demo",
        },
        buttonDemoHref: {
          userStudent: externalLinks.publicDemoTypeFormLink,
          userRecruiter: externalLinks.publicDemoTypeFormLink,
        },
      },
    },
    userStudent: {
      jobs: {
        title: "AQUÍ ESTÁ TU SIGUIENTE EMPLEO",
        subTitle: "Top oportunidades",
      },
      firstSection: {
        title: "PASOS A SEGUIR PARA BUSCAR TRABAJO",
        subTitle: "Así de sencillo",
        1: {
          title: "CREA TU PERFIL PROFESIONAL",
          subTitle: "Crea tu perfil",
          description:
            "Regístrate y personaliza tu perfil con tu experiencia y preferencias. Nuestra IA procesará tu información para conectarte con las mejores oportunidades laborales, maximizando tus posibilidades de éxito en la búsqueda de tu empleo ideal.",
          imgAlt: "Crea tu perfil profesional",
        },
        2: {
          title: "BOLSA DE TRABAJO PARA ESTUDIANTES",
          subTitle: "Dale Like a las Oportunidades",
          description:
            "Explora empleos de manera interactiva. Con nuestro sistema de 'likes', puedes revisar rápidamente las ofertas y seleccionar las que más te interesan, facilitando un proceso ágil y adaptado a tus necesidades",
          imgAlt: "Bolsa de trabajo para estudiantes",
        },
        3: {
          title: "CONSIGUE TU PRIMER EMPLEO",
          subTitle: "Haz Match con Empleadores",
          description:
            "Una vez que encuentres ofertas que te interesen, la IA de Koble te ayudará a hacer match con empleadores que buscan candidatos con tu perfil. Agilizamos el proceso de selección y promovemos el match perfecto entre tus habilidades y las necesidades de la empresa.",
          imgAlt: "Consigue tu primer empleo",
        },
      },
      secondSection: {
        title: "¿POR QUÉ UNIRTE A KOBLE?",
        subTitle: "Contacto Directo con Empleadores",
        button: "Empieza ahora",
        1: {
          title: "Recibe Alertas de Empleos a Tu Medida",
          subTitle:
            "Entérate de las vacantes que coinciden con tu perfil vía WhatsApp y SMS.",
        },
        2: {
          title: "Te Ayudamos a Destacar",
          subTitle:
            "Mejora tu perfil con nuestra inteligencia artificial... ¡con un solo clic!",
        },
        3: {
          title: "Contacto Directo con Empleadores",
          subTitle:
            "Con nuestra interfaz podrás chatear directamente con reclutadores, sin intermediarios.",
        },
        4: {
          title: "Registro Gratis",
          subTitle:
            "Porque arrancar tu carrera no tiene que ser cuesta arriba.",
        },
      },
      testimony: {
        title: "POTENCIA TU CARRERA CON TECNOLOGÍA",
        subTitle:
          "Descubre cómo es la experiencia de encontrar trabajo a través de nosotros",
        name: "Juan Pablo Lopez Alvarado",
        position:
          "Estudiante de Inteligencia de Negocios, Intern Project Manager en Lumston GDL",
        text: "Sin Koble, seguiría buscando en un mar de vacantes de empresas que buscan talento con experiencia, cosa que yo no tenía. La plataforma es súper fácil de usar, me tomó 10 minutos armar mi perfil y poder empezar a buscar entre las opciones que hay en el sitio. Ya se nos acabaron las excusas para conseguir trabajo, y todo gracias a Koble.",
      },
      fourthSection: {
        title: "DEMOCRATIZAMOS LAS OPORTUNIDADES DE EMPLEO",
        subTitle:
          "El lugar donde nuevos profesionales crean carreras significativas",
        description:
          "Trabajamos incansablemente para ser el puente que conecta a las próximas generaciones de profesionales con oportunidades laborales que impulsen tanto el éxito de las empresas como la realización personal de los individuos, promoviendo un ambiente de igualdad de oportunidades.",
      },
    },
    textCarousel: {
      title: "Logra el match perfecto entre talento y oportunidad",
      subTitle:
        "Nuestro objetivo es claro: Lograr el Match perfecto entre tu talento y las oportunidades.",
    },
    userRecruiter: {
      firstSection: {
        title: "LA HERRAMIENTA PARA RECLUTAMIENTO IMPULSADA CON IA",
        subTitle: "¿Cómo funciona Koble?",
        button: "Prueba Koble",
        1: {
          title: "CREA TU PERFIL",
          subTitle: "Publica tus anuncios de empleo en tiempo record",
          description:
            "Nuestro sistema está diseñado para hacer que la contratación sea más fácil y eficiente. Desde la creación de un perfil empresarial hasta la recepción de recomendaciones de candidatos potenciales, todo está optimizado para ahorrar tiempo y costos.",
          imgAlt: "Crea tu perfil profesional",
        },
        2: {
          title: "EXPLORA TALENTO DE UN BASTO NÚMERO DE UNIVERSIDADES",
          subTitle: "Accede desde cualquier lugar, en cualquier momento",
          description:
            "Encuentra al talento joven perfecto para tu empresa, con nuestro Koble Matchscore y filtros avanzados.",
          imgAlt: "Bolsa de trabajo para estudiantes",
        },
        3: {
          title: "COMIENZA A CONTRATAR CON KOBLE",
          subTitle:
            "Invita al talento a colaborar en tu empresa con un solo click",
          description:
            "Usa tus Likes y Súper Likes para invitar al talento a colaborar en tu empresa con un solo click. Recibirán una notificación y si responden tu invitación, ¡tienes un Match!",
          imgAlt: "Consigue tu primer empleo",
        },
        4: {
          title: "GESTIONA TU PROCESO EN UN SOLO LUGAR",
          subTitle: "Revoluciona la manera de contratar con Koble",
          description:
            "Con nuestro Sistema de Seguimiento de Aplicantes, lleva el control de tus Matches, las fases en las que van sus procesos de contratación y tus conversasiones del chat con el talento.",
          imgAlt: "Consigue tu primer empleo",
        },
      },
      secondSection: {
        title: "NUESTRAS RAZONES DE EXISTIR",
        subTitle: "¿Por qué Koble es tu mejor aliado en reclutamiento?",
        1: {
          title: "Inteligencia Artificial aplicada",
          description:
            "Crear tus anuncios de empleo y analiza prospectos con ayuda de AI.",
          imgAlt: "Inteligencia Artificial aplicada",
        },
        2: {
          title: "Plataforma Intuitiva",
          description: "La platarforma más fácil de usar del mercado.",
          imgAlt: "Plataforma Intuitiva",
        },
        3: {
          title: "Crea tu perfil",
          description:
            "Genera confianza en los talentos jovenes creando tu perfil de reclutador.",
          imgAlt: "Crea tu perfil",
        },
        4: {
          title: "Alcance amplificado",
          description:
            "Activa tu anuncio y explora talento joven de la mayoría de las univerisidades del país.",
          imgAlt: "Alcance amplificado",
        },
      },
      testimony: {
        title: "POTENCIA TU CARRERA CON TECNOLOGÍA",
        subTitle:
          "No te lo decimos nosotros... Esto es lo que el talento piensa de su experiencia en Koble:",
        name: "Miguel Argento",
        position: "CEO Perro Argento",
        text: "Mi empresa es relativamente nueva, tenía cero experiencia en contrataciones, y estaba en búsqueda de un Community Manager. Koble me ayudó ofreciendo una plataforma amigable, muy fácil de usar y en dos semanas ya había cerrado mi contratación. Lo recomiendo ampliamente y les invitó a usar Koble para la contratación de talento jóven y emergente. ",
      },

      thirdSection: {
        title: "EVALUADO CON INTELIGENCIA ARTIFICIAL",
        subTitle: "Contratación con datos",
        description: {
          1: {
            title: "Contrataciones Precisas",
            description:
              "La IA identifica candidatos ideales mediante análisis de datos, elevando la calidad de tus contrataciones.",
          },
          2: {
            title: "Menos Sesgos:",
            description:
              "Los algoritmos de IA reducen los sesgos en el proceso de selección, fomentando un ambiente laboral más diverso y equitativo.",
          },
          3: {
            title: "Ahorro de Tiempo:",
            description:
              "La IA automatiza tareas como la revisión de currículos, optimizando recursos y permitiendo a los reclutadores enfocarse en decisiones más estratégicas.",
          },
          4: {
            title: "Previsión de Talento",
            description:
              "El análisis predictivo de IA anticipa necesidades futuras de talento, preparando a las empresas para cambios en la fuerza laboral.",
          },
        },
      },
      fourthSection: {
        title: "DEMOCRATIZAMOS LAS OPORTUNIDADES DE EMPLEO ",
        subTitle: "El lugar donde las organizaciones hallan su próximo talento",
        description:
          "Trabajamos incansablemente para ser el puente que conecta a las próximas generaciones de profesionales con oportunidades laborales que impulsen tanto el éxito de las empresas como la realización personal de los individuos, promoviendo un ambiente de igualdad de oportunidades.",
      },
    },
    metadata: {
      title: "Koble - Empleos para Estudiantes y Recién Egresados",
      description:
        "Descubre empleos para estudiantes y recién egresados en nuestra bolsa de trabajos. Conecta con empleadores listos para contratarte",
      userStudentSlug: "/estudiantes",
      userRecruiterSlug: "/reclutadores",
    },
    preFooter: {
      userStudent: {
        title: "DONDE EL TALENTO SE ENCUENTRA CON LAS OPORTUNIDADES",
        subTitle: "Da el primer paso hacia tu nuevo empleo, ¡empieza ahora!",
        description:
          "Tan sencillo como crear tu perfil, y dar like hasta hacer match con tu próximo gran empleo. ¿Qué esperas?",
        button: "Inicia tu camino profesional aquí",
      },
      userRecruiter: {
        title: "TU RED DE OPORTUNIDADES LABORALES ",
        subTitle: "Maximiza tu proceso de reclutamiento",
        description:
          "Utiliza nuestras herramientas avanzadas para crear un perfil de empresa atractivo y efectivo. Aprovecha nuestra base de datos para alcanzar a los candidatos más prometedores y hacer que cada contratación sea un éxito.",
        button: "Crea tu perfil aquí",
      },
    },
    footer: {
      logoAlt: "Logo de Koble",
      sections: {
        title: "Secciones",
        userStudents: "Estudiantes",
        userStudentsTitle: "Estudiantes",
        userRecruiters: "Reclutadores",
        userRecruitersTitle: "Reclutadores",
        termsAndConditions: "Términos y condiciones",
        termsAndConditionsTitle: "Términos y condiciones",
        termsAndConditionsLink: "/terminos-y-condiciones",
        privacyPolicy: "Aviso de privacidad",
        privacyPolicyTitle: "Aviso de privacidad",
        privacyPolicyLink: "/aviso-de-privacidad",
      },
      contact: {
        title: "Atención al cliente",
        phone: "+52 33 1717 2940",
        phoneLink: "https://wa.me/523317172940",
        phoneTitle: "Teléfono de Koble",
        email: "soporte@koble.mx",
        emailLink: "mailto:soporte@koble.mx",
        emailTitle: "Correo de Koble",
      },
      payments: {
        title: "Métodos de pago",
      },
      socialMedia: {
        title: "Redes sociales",
        instagramLink: "https://www.instagram.com/koble.mx/",
        instagramAlt: "Instagram de Koble",
        linkedinLink: "https://www.linkedin.com/company/koblemx/",
        linkedinAlt: "Linkedin de Koble",
        facebookLink: "https://www.facebook.com/profile.php?id=100091484634488",
        facebookAlt: "Facebook de Koble",
        tikTokLink: "https://www.tiktok.com/@koblemx",
        tikTokAlt: "TikTok de Koble",
      },
    },
    contact: {
      title: "¡Hola soy Kobot!",
      subTitle: ["Bienvenido al soporte técnico ¿Cómo puedo atenderte?", ""],
      footer: "Inicia una conversación",
      button: "Soporte técnico",
    },
  },
  termsAndConditions: {
    logoAlt: "Logo de Koble",
  },
  metadata: {
    title: {
      userStudent:
        "Koble - Empleos para estudiantes y recién egresados en nuestra bolsa ",
      userRecruiter:
        "Koble - Herramientas para el reclutamiento y selección de personal",
    },
    description: {
      userStudent:
        "Descubre empleos para estudiantes y recién egresados en nuestra bolsa de trabajos. Conecta con empleadores listos para contratarte",
      userRecruiter:
        "Innovación en reclutamiento en México: conecta con universitarios y recién egresados usando nuestra plataforma con inteligencia artificial.",
    },
    image: {
      userStudent: `https://koble.mx${assets.PUBLIC_COVER_USER_STUDENT}`,
      userRecruiter: `https://koble.mx${assets.PUBLIC_COVER_USER_RECRUITER}`,
    },
  },
};

export default es;
